<i18n>
{
  "ru": {
    "title": "История изменений"
  },
  "he": {
    "title": "שינויים בהזמנה"
  }
}
</i18n>

<template>
  <div>
    <h1 class="text-h4 mb-2">{{ $t('title') }}</h1>

    <v-data-table
      :headers="headers"
      :items="items"
      :items-per-page="10"
      class="elevation-1"
      :footer-props="footer"
      :server-items-length="total"
      :page.sync="page"
      :loading="loading"
    >
      <template v-slot:item.btn="{ item }">
        <v-menu
          bottom
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              dark
              icon
              color="teal"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon dark>
                mdi-dots-vertical
              </v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item
              @click="showItem = item.ID"
            >
              <v-list-item-title>
                {{ $t('btn.detail') }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>

    <template v-if="showItem">
      <HistoryOrdersPopup
        :id="showItem"
        @close="showItem = null"
      ></HistoryOrdersPopup>
    </template>
  </div>
</template>

<script>
import HistoryOrdersPopup from '@/views/HistoryOrders/Popup.vue';

export default {
  name: 'HistoryOrdersList',
  components: {
    HistoryOrdersPopup,
  },
  props: {
    orderId: {
      type: Number,
      required: true,
    },
  },
  data: (vm) => ({
    items: [],
    showItem: null,
    headers: [
      {
        text: vm.$t('props.UF_DATE'),
        value: 'UF_DATE',
        sortable: false,
      },
      {
        text: vm.$t('props.UF_USER'),
        value: 'USER_NAME',
        sortable: false,
      },
      {
        text: vm.$t('props.UF_TYPE'),
        value: 'TYPE_NAME',
        sortable: false,
      },
      {
        text: '',
        value: 'btn',
        align: 'right',
        sortable: false,
      },
    ],
    footer: {
      'disable-items-per-page': true,
      'items-per-page-options': [50, 100],
    },
    page: 1,
    limit: 50,
    total: 0,
    loading: false,
  }),
  watch: {
    page() {
      this.getItems();
    },
  },
  methods: {
    getItems() {
      this.loading = true;

      const params = {
        order: {
          UF_DATE: 'DESC',
          ID: 'DESC',
        },
        filter: {
          UF_ORDER: this.orderId,
        },
        select: ['ID', 'UF_DATE', 'UF_TYPE', 'UF_USER'],
        limit: this.limit,
        offset: (this.page - 1) * this.limit,
      };

      this.$store.dispatch('getHistoryOrdersList', { params }).then((response) => {
        this.items = response.data.data.result;
        this.total = response.data.data.count;

        this.loading = false;
      });
    },
  },
  created() {
    this.getItems();
  },
};
</script>
