module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "ru": {
        "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Итого"])},
        "receipts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прием"])},
        "delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доставка"])},
        "allSum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общая сумма"])},
        "paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оплачено"])},
        "diff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Осталось"])}
      },
      "he": {
        "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["סה''כ"])},
        "receipts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["קבלה"])},
        "delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["משלוח"])},
        "allSum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["סך הכל"])},
        "paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["בתשלום"])},
        "diff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["נשאר"])}
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"ru":{"total":"Итого","receipts":"Прием","delivery":"Доставка","allSum":"Общая сумма","paid":"Оплачено","diff":"Осталось"},"he":{"total":"סה\u0027\u0027כ","receipts":"קבלה","delivery":"משלוח","allSum":"סך הכל","paid":"בתשלום","diff":"נשאר"}}')
  delete Component.options._Ctor
  
}
