module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "ru": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["История изменений"])}
      },
      "he": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["שינויים בהזמנה"])}
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"ru":{"title":"История изменений"},"he":{"title":"שינויים בהזמנה"}}')
  delete Component.options._Ctor
  
}
