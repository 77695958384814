<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="700"
  >
    <v-card>
      <v-card-text>
        <HistoryOrdersDetail
          :id="id"
        ></HistoryOrdersDetail>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <template>
          <v-btn
            color="teal"
            text
            @click="$emit('close', false)"
          >
            {{ $t('btn.close') }}
          </v-btn>
        </template>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import HistoryOrdersDetail from '@/views/HistoryOrders/Detail.vue';

export default {
  name: 'HistoryOrdersPopup',
  components: {
    HistoryOrdersDetail,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    dialog: true,
  }),
};
</script>
