<template>
  <v-autocomplete
    :value="value"
    :items="items"
    :label="label"
    item-text="NAME"
    item-value="ID"
    :disabled="disabled"
    :required="required"
    :dense="dense"
    :rules="rules"
    :search-input.sync="search"
    return-object
    clearable
    color="teal"
    @change="change($event)"
  ></v-autocomplete>
</template>

<script>
export default {
  name: 'AutocompleteUser',
  props: {
    value: {
      required: false,
    },
    label: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    dense: {
      type: Boolean,
      required: false,
      default: false,
    },
    rules: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data: () => ({
    search: '',
    items: [],
    timer: null,
  }),
  watch: {
    search(val) {
      const findItem = this.items.find((item) => item.NAME === val);
      if (findItem) return false;

      if (val && val.length >= 5) {
        if (this.timer) clearTimeout(this.timer);

        this.timer = setTimeout(() => {
          this.getUsers(val);
        }, 500);
      } else {
        this.items = [];
      }

      return val;
    },
  },
  methods: {
    getUsers(val) {
      const params = {
        order: {
          'USER.NAME': 'ASC',
        },
        filter: {
          'USER.ACTIVE': 'Y',
          '%=USER.NAME': `%${val}%`,
        },
        select: {
          ID: 'USER.ID',
          NAME: 'USER.NAME',
        },
        limit: 10,
        offset: 0,
      };

      this.$store.dispatch('getUsers', { params }).then((response) => {
        this.items = response.data.data.result;
      });
    },
    getUser() {
      const params = {
        order: {
          'USER.NAME': 'ASC',
        },
        filter: {
          ID: this.value,
        },
        select: {
          ID: 'USER.ID',
          NAME: 'USER.NAME',
        },
        limit: 1,
        offset: 0,
      };

      this.$store.dispatch('getUsers', { params }).then((response) => {
        this.items = response.data.data.result;
      });
    },
    change(val) {
      const value = (val) ? val.ID : null;

      this.$emit('change', value);
      this.$emit('input', value);
      this.$emit('inputObject', val);
    },
  },
  created() {
    if (this.value) {
      this.getUser();
    }
  },
};
</script>
