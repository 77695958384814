module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "ru": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменение заказа"])},
        "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Итого"])},
        "receipts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прием"])},
        "delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доставка"])},
        "allSum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общая сумма"])},
        "paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оплачено"])},
        "diff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Осталось"])},
        "changeReceipts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменить прием"])},
        "changeDelivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменить доставку"])},
        "contractorsInWorks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(У&nbsp;подрядчика)"])},
        "newPhoto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новые фотографии"])},
        "uploadPhoto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загруженные фотографии"])}
      },
      "he": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["שינוי הזמנה"])},
        "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["סה''כ"])},
        "receipts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["קבלה"])},
        "delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["משלוח"])},
        "allSum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["סך הכל"])},
        "paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["בתשלום"])},
        "diff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["נשאר"])},
        "changeReceipts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["שינוי הקבלה"])},
        "changeDelivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["שנה משלוח"])},
        "contractorsInWorks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(נשלח&nbsp;לקבלן)"])},
        "newPhoto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["תמונות חדשות"])},
        "uploadPhoto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["תמונות שהועלו"])}
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"ru":{"title":"Изменение заказа","total":"Итого","receipts":"Прием","delivery":"Доставка","allSum":"Общая сумма","paid":"Оплачено","diff":"Осталось","changeReceipts":"Изменить прием","changeDelivery":"Изменить доставку","contractorsInWorks":"(У&nbsp;подрядчика)","newPhoto":"Новые фотографии","uploadPhoto":"Загруженные фотографии"},"he":{"title":"שינוי הזמנה","total":"סה\u0027\u0027כ","receipts":"קבלה","delivery":"משלוח","allSum":"סך הכל","paid":"בתשלום","diff":"נשאר","changeReceipts":"שינוי הקבלה","changeDelivery":"שנה משלוח","contractorsInWorks":"(נשלח&nbsp;לקבלן)","newPhoto":"תמונות חדשות","uploadPhoto":"תמונות שהועלו"}}')
  delete Component.options._Ctor
  
}
